import { Link } from "gatsby"
import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

const Blog = ({ title, uri, featuredImage }) => {
  return (
    <div className="w-full lg:w-2/4 p-10 md:px-10">
      <Link
        to={uri}
        itemProp="url"
        className="block text-black font-bold text-xl text-left py-5"
      >
        {featuredImage?.data && (
          <GatsbyImage
            className="block w-full mb-4"
            image={featuredImage?.data}
            alt={featuredImage.alt}
          />
        )}
        {parse(title)}
      </Link>

      <Link
        className="block text-black font-thin text-xl text-left underline"
        to={uri}
      >
        → Read more
      </Link>
    </div>
  )
}

export default Blog
